import classNames from 'classnames/bind';
import React from 'react';

import { Footer } from '@components/Footer/Footer';
import { Nav } from '@containers/Nav/Nav';

import styles from './Layout.module.scss';

interface IProps {
  isLightNav?: boolean;
  className?: string;
  contentClassName?: string;
  footerClassName?: string;
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Layout: React.FC<IProps> = ({
  isLightNav,
  className,
  contentClassName,
  footerClassName,
  children,
}) => (
  <div className={cx('layout', className)}>
    <Nav isLight={isLightNav} />
    <div className={cx('content', contentClassName)}>{children}</div>
    <Footer className={footerClassName} />
  </div>
);
