import { useRouter } from 'next/router';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import { Nav as NavComponent } from '@components/Nav/Nav';
import { SimpleNav } from '@components/Nav/SimpleNav/SimpleNav';
import { AuthContainer } from '@hooks/authContainer';
import { useScrollLock } from '@hooks/useScrollLock';
import { useWindowSize } from '@hooks/useWindowSize';
import { routes } from '@routes';

const mobileNavBreakpoint = 992;

interface Props {
  isLight?: boolean;
}

export const Nav: React.FC<Props> = ({ isLight }) => {
  const { authState } = AuthContainer.useContainer();
  const { enableScroll, disableScroll } = useScrollLock();
  const { width } = useWindowSize();
  const [ref, isOnTop] = useInView({ threshold: 1 });
  const [isMobileMenu, setIsMobileMenu] = React.useState(
    width ? width < mobileNavBreakpoint : false,
  );
  const [isHydrated, setIsHydrated] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    const isMobileResolution = width ? width < mobileNavBreakpoint : false;
    setIsMobileMenu(isMobileResolution);

    if (!isMobileResolution) {
      enableScroll();
    }
  }, [width]);

  React.useEffect(() => {
    setIsHydrated(true);
  }, []);

  const handleMenuToggle = () => {
    if (isOpen) {
      enableScroll();
    } else {
      disableScroll();
    }

    setIsOpen(!isOpen);
  };

  const handleMenuClose = () => {
    enableScroll();

    setIsOpen(false);
  };

  const pagesWithoutNav = [routes.tlsTestResult];
  if (router?.pathname && pagesWithoutNav.includes(router.pathname)) {
    return null;
  }

  const pagesWithSimpleNav = [routes.tlsTest];
  if (router?.pathname && pagesWithSimpleNav.includes(router.pathname)) {
    return <SimpleNav />;
  }

  return (
    <>
      <div ref={ref} />
      <NavComponent
        isLight={isLight}
        isMobileMenu={isMobileMenu}
        isOpen={isOpen && isMobileMenu}
        isScrolled={isHydrated && !isOnTop}
        isUserLoggedIn={authState.id !== null}
        onCloseMenu={handleMenuClose}
        onLinkClick={handleMenuClose}
        onToggleMenu={handleMenuToggle}
      />
    </>
  );
};
