export const minPrice = 199;

export const cdnStats = {
  capacity: {
    network: '215 Tbps',
    backbone: '26 Tbps',
  },
  dailyTraffic: {
    video: '300 PB',
    total: '340 PB',
  },
  trafficPeak: {
    northAmerica: '23 Tbps',
    europe: '24 Tbps',
    asiaAndPacific: '14.6 Tbps',
    southAmerica: '7 Tbps',
    africa: '5 Tbps',
    total: '65 Tbps',
  },
  avgCashHit: '98%',
  ispConnected: '3 000',
  pnis: '300+',
  tier1TransitProviders: 16,
  continentsCount: 6,
  locationsCount: {
    fullPops: 119,
    onDemand: 30,
  },
  yearsOnMarket: 13,
} as const;

export const support = {
  email: 'support@cdn77.com',
  telegram: 'cdn77support',
  workingHours: '8-15 (GMT)',
} as const;

export const sales = {
  email: 'sales@cdn77.com',
  telegram: 'Katerina_CDN77',
  workingHours: '8-16 (GMT)',
} as const;

export const abuse = {
  email: 'abuse@datacamp.co.uk',
} as const;

export const bugBounty = {
  email: 'bugbounty@cdn77.com',
};

export const skype = 'Skype:live:.cid.b5f6f3e971d06828?chat';

export const datacampAddress = {
  street: '9 Coldbath Square',
  city: 'London',
  cityDistrict: 'Clerkenwell',
  postalCode: 'EC1R 5HL',
  country: 'United Kingdom',
  countryCode: 'UK',
} as const;
