import React from 'react';

import useEventListener from './useEventListener';

export const breakpointSize = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1240,
  xxl: 1420,
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEventListener('resize', handleResize);

  React.useEffect(() => {
    handleResize();
  }, []);

  return windowSize;
};
