import classNames from 'classnames/bind';
import React from 'react';

import { ContactLinks } from './ContactLinks/ContactLinks';
import { footerGroups } from './footerData';
import { Button } from '@components/Button/Button';
import { SimpleFooter } from '@components/Footer/SimpleFooter/SimpleFooter';
import { Col } from '@components/Layout/Col/Col';
import { Container } from '@components/Layout/Container/Container';
import { Row } from '@components/Layout/Row/Row';
import { Logo } from '@components/Logo/Logo';
import { HideableMobileSection } from '@containers/HideableMobileSection/HideableMobileSection';

import styles from './Footer.module.scss';
import logoCdn77 from '@components/Footer/cdn77.svg';
import DownloadIcon from '@icons/download.svg';

interface Props {
  className?: string;
}

const cx = classNames.bind(styles);

export const Footer: React.FC<Props> = ({ className }) => (
  <footer className={cx('footer', className)} data-testid="footer">
    <div className={styles.main}>
      <Container>
        <Row gapY="none" horizontalAlign="between" tagName="ul">
          <Col className={styles.contact} gapY="none" lg={3} tagName="li">
            <Logo className="mb-5" height={30} name="CDN77" src={logoCdn77} width={103} />
            <ContactLinks />
          </Col>
          <li className={styles.categoryGroup}>
            {footerGroups.map(({ title, links }) => (
              <div key={title} className={styles.category}>
                <strong className={cx('title', 'mobileHide')}>{title}</strong>
                <div className={styles.group}>
                  <HideableMobileSection className={styles.section} theme="tertiary" title={title}>
                    <ul className={styles.list}>
                      {links.map(({ title, href, external, download }) => (
                        <li key={title} className={styles.wrapper}>
                          <Button
                            className={cx('link', 'text-regular')}
                            download={download}
                            external={external}
                            href={href}
                            icon={<DownloadIcon />}
                            noIcon={!download}
                            variant="inherit"
                          >
                            {title}
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </HideableMobileSection>
                </div>
              </div>
            ))}
          </li>
        </Row>
      </Container>
    </div>
    <SimpleFooter />
  </footer>
);
