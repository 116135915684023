import classNames from 'classnames/bind';
import React from 'react';

import type { PaddingSize, Size } from './usePaddingContext';
import { PaddingContext } from './usePaddingContext';

import styles from './Padding.module.scss';

export const defaultSize: Size = 48;

export interface Props {
  id?: string;
  tag?: 'section' | 'div' | 'span' | 'header' | 'main';
  size?: PaddingSize;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
}

const cx = classNames.bind(styles);

export const Padding: React.FC<Props> = ({
  id,
  tag: Tag = 'div',
  size = 48,
  className,
  style,
  onClick,
  children,
}) => (
  <PaddingContext.Provider value={size ?? defaultSize}>
    <Tag
      className={cx(
        'wrapper',
        `padding-left-${typeof size === 'number' ? size : size.left ?? size.x ?? defaultSize}`,
        `padding-right-${typeof size === 'number' ? size : size.right ?? size.x ?? defaultSize}`,
        `padding-top-${typeof size === 'number' ? size : size.top ?? size.y ?? defaultSize}`,
        `padding-bottom-${typeof size === 'number' ? size : size.bottom ?? size.y ?? defaultSize}`,
        className,
      )}
      id={id}
      style={style}
      onClick={onClick}
    >
      {children}
    </Tag>
  </PaddingContext.Provider>
);
