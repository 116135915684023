import { routes, externalRoutes } from '@routes';

interface IFooterLink {
  href: string;
  title: string;
  download?: boolean;
  icon?: string;
  external?: boolean;
}

interface IFooterGroup {
  title: string;
  links: IFooterLink[];
}

export const footerGroups: IFooterGroup[] = [
  {
    title: 'Product',
    links: [
      { href: routes.network, title: 'Network' },
      { href: routes.security, title: 'Security' },
      { href: routes.videoCdn, title: 'Video delivery' },
      { href: routes.pricing, title: 'Pricing' },
      { href: routes.features, title: 'CDN features' },
      {
        href: externalRoutes.client.support,
        title: 'Docs & API',
        external: true,
      },
    ],
  },
  {
    title: 'Company',
    links: [
      { href: routes.aboutUs, title: 'About CDN77' },
      { href: routes.contact, title: 'Contact' },
      { href: routes.reviews, title: 'Meet our clients' },
    ],
  },
  {
    title: 'Legal',
    links: [
      { href: routes.termsAndConditions, title: 'Terms & Conditions' },
      { href: routes.serviceLevelAgreement, title: 'Service Level Agreement' },
      { href: routes.acceptableUsePolicy, title: 'Acceptable Use Policy' },
      { href: '/DSA-policy.pdf', title: 'DSA policy', download: true },
      { href: '/DMCA-policy.pdf', title: 'DMCA policy', download: true },
      { href: routes.privacyPolicy, title: 'Privacy policy' },
      { href: routes.cookiePolicy, title: 'Cookie policy' },
      { href: routes.trust, title: 'Trust & DMCA' },
    ],
  },
];
